import { useFetcher, useLocation } from '@remix-run/react';
import { useTypedLoaderData as useLoaderData } from 'remix-typedjson';

import { LoginActions, type action, type loader } from '~/routes/_auth.login';

import { twoFactorSchema } from '~/data/auth/.schema/twoFactorSchema';

import { Form } from '~/components/.shared/form/Form';

export function TwoFactorForm() {
  const fetcher = useFetcher<typeof action>();
  const data = useLoaderData<typeof loader>();
  const location = useLocation();

  return (
    <Form
      FetcherForm={fetcher.Form}
      fetcherData={fetcher.data}
      method="post"
      action={`${location.pathname}${location.search}`}
      schema={twoFactorSchema}
      reloadDocument
    >
      <Form.Fieldset>
        <Form.InputField type="text" name="code" inputMode="numeric" autoComplete="off" />
        <input type="hidden" name="redirectUrl" value={data.data?.redirectUrl || ''} />

        <Form.SubmitButton className="justify-center w-full" value={LoginActions.TwoFactor}>
          Log in
        </Form.SubmitButton>
      </Form.Fieldset>
    </Form>
  );
}
