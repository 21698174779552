import { z } from 'zod';

import { EMAIL_REGEX } from '~/helpers/auth/emailRegex';

export const loginSchema = z.object({
  email: z.string().regex(EMAIL_REGEX, { message: 'Please enter your email.' }),
  password: z.string().min(1, { message: 'Please enter a password.' }),
});

export const loginWithSecurityCheckSchema = z.object({
  email: z.string().email({ message: 'Please enter your email.' }),
  password: z.string().min(1, { message: 'Please enter a password.' }),
  'h-captcha-response': z.string().min(1, { message: 'Please complete a successful hCaptcha test.' }),
});
