import { LoaderIcon } from '@gathercontent/icons';
import { Link, useSearchParams } from '@remix-run/react';
import { useContext } from 'react';

import { useUser } from '~/data/user/hooks/useUser';

import { checkIsIFramedContent } from '~/helpers/dom/checkIsIFramedContent';
import { useHydrated } from '~/helpers/hooks/useHydrated';

import { CONTACT_SUPPORT_MODAL_ID } from '~/components/contact-support/modal/ContactSupportModal';

import { ModalContext } from '../.shared/modal/ModalProvider';
import { LoginForm } from './LoginForm';
import { LoginWelcomeMessage } from './LoginWelcomeMessage';

export function Login() {
  const [searchParams] = useSearchParams();
  const isPreviewContactSupportFeature = Boolean(searchParams.get('previewContactSupportFeature'));
  const hydrated = useHydrated();
  const user = useUser();
  const shouldRedirectParentWindow = checkIsIFramedContent();

  const { showModal } = useContext(ModalContext);

  if (shouldRedirectParentWindow) {
    const parentPath = window.parent.location.pathname;
    const origin = window.location.origin;
    const loginWithParentRedirect = `${origin}/login?redirect=${origin + parentPath}`;

    window.parent.location.assign(loginWithParentRedirect);

    return (
      <div className="flex justify w-full">
        <LoaderIcon iconSize="24" />
      </div>
    );
  }

  return (
    <>
      <LoginWelcomeMessage />
      {!user && (
        <>
          <LoginForm />
          <div className="text-center mt-6">
            <Link to="/forgotten">Forgot password?</Link>
            {isPreviewContactSupportFeature && (
              <>
                <span className="mx-2">&bull;</span>
                <Link
                  to="/contact-support"
                  onClick={(e) => {
                    if (hydrated) {
                      e.preventDefault();
                      showModal(CONTACT_SUPPORT_MODAL_ID);
                    }
                  }}
                >
                  Contact support
                </Link>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
