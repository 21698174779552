import { LoggedInMessage } from '~/components/.shared/auth/LoggedInMessage';

export function LoginWelcomeMessage() {
  return (
    <>
      <h1>Log in</h1>
      <LoggedInMessage />
    </>
  );
}
