import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import {
  typedjson as json,
  useTypedActionData as useActionData,
  useTypedLoaderData as useLoaderData,
} from 'remix-typedjson';
import type { loader as rootLoader } from '~/root';

import { createDataFunctionSuccess } from '~/data/.shared/success/createDataFunctionSuccess';
import type { RouteData } from '~/data/.types/routeTypes';
import type { LoginApiResponse } from '~/data/auth/.types/loginTypes';
import { login } from '~/data/auth/api/login';
import { loginRedirect } from '~/data/auth/api/loginRedirect';
import { recovery } from '~/data/auth/api/recovery';
import { twofactor } from '~/data/auth/api/twofactor';
import { verifyUser } from '~/data/auth/api/verifyUser';

import { throwNotImplemented } from '~/helpers/remix/responses/throwNotImplemented';
import { setupFormDataAction } from '~/helpers/remix/routes/setupFormDataAction';

import { Login } from '~/components/login/Login';
import { Recovery } from '~/components/login/recovery/Recovery';
import { TwoFactor } from '~/components/login/twoFactor/TwoFactor';

export const LoginActions = {
  Login: 'login',
  TwoFactor: 'twofactor',
  Recovery: 'recovery',
} as const;

export async function action(args: ActionFunctionArgs) {
  const data = await setupFormDataAction(args);
  const action = data.get('_action');

  if (action === LoginActions.Login) {
    const dataFunctionResult = await login({
      request: args.request,
      email: data.get('email') as string,
      password: data.get('password') as string,
      'h-captcha-response': data.get('h-captcha-response') as string,
    });
    return loginRedirect(args, dataFunctionResult);
  }

  if (action === LoginActions.TwoFactor) {
    const dataFunctionResult = await twofactor({ request: args.request, code: data.get('code') as string });
    return loginRedirect(args, dataFunctionResult);
  }

  if (action === LoginActions.Recovery) {
    const dataFunctionResult = await recovery({
      request: args.request,
      recovery_code: data.get('recovery_code') as string,
    });
    return loginRedirect(args, dataFunctionResult);
  }

  throwNotImplemented();
}

export type LoginLoaderData = RouteData<{
  twoFactor: string | null;
  redirectUrl: string | null;
  recovery: string | null;
}>;

export async function loader({ request, params }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const twoFactorEnabled = url.searchParams.get('2fa');
  const recovery = url.searchParams.get('recovery');
  const redirectUrl = url.searchParams.get('redirect');
  await verifyUser({ request, params });

  return json(
    createDataFunctionSuccess({
      twoFactor: twoFactorEnabled,
      recovery,
      redirectUrl,
    }),
  );
}

export default function LoginPage() {
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<RouteData<LoginApiResponse>>();
  const showTwoFactor =
    actionData?.data?.two_factor || actionData?.error?.values?._action === 'twofactor' || loaderData?.data?.twoFactor;
  const showRecovery = loaderData?.data?.recovery || actionData?.error?.values?._action === 'recovery';

  if (showRecovery) return <Recovery />;
  else if (showTwoFactor) return <TwoFactor />;

  return <Login />;
}

export const meta: MetaFunction<typeof rootLoader, { root: typeof rootLoader }> = () => {
  return [
    {
      title: 'Content Workflow',
    },
    {
      name: 'description',
      content: `Log in to your Content Workflow account.`,
    },
  ];
};
