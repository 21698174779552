import { Link } from '@remix-run/react';
import { useTypedActionData as useActionData } from 'remix-typedjson';

import type { RouteData } from '~/data/.types/routeTypes';
import type { LoginApiResponse } from '~/data/auth/.types/loginTypes';

import { RecoveryForm } from './RecoveryForm';

export function Recovery() {
  const actionData = useActionData<RouteData<LoginApiResponse>>();

  const recoveryFailed = actionData?.error?.values?._action === 'recovery' && actionData?.error?.type === 'api';

  return (
    <>
      {recoveryFailed ? (
        <>
          <h1>Incorrect 2FA recovery code</h1>
          <p>The recovery code you entered was incorrect and you will need to start again.</p>
          <Link to="/login">Back to login</Link>
        </>
      ) : (
        <>
          <h1>2FA recovery</h1>
          <p>Having trouble with your 2FA? Enter one of your recovery codes below and we'll get you on your way.</p>
          <RecoveryForm />
          <div className="text-center mt-6">
            <Link to="/login">Go back</Link>
          </div>
          <div className="text-center mt-3">
            <a
              href="https://help.gathercontent.com/en/articles/3095685-changing-your-personal-settings#h_7f7707377f"
              target="_blank"
            >
              Read the 2FA help docs
            </a>
          </div>
        </>
      )}
    </>
  );
}
