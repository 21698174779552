import { useLocation } from '@remix-run/react';
import {
  useTypedActionData as useActionData,
  useTypedFetcher as useFetcher,
  useTypedLoaderData as useLoaderData,
} from 'remix-typedjson';

import { LoginActions, type loader } from '~/routes/_auth.login';

import type { DataFunctionResult } from '~/data/.types/dataFunctionTypes';
import type { RouteData } from '~/data/.types/routeTypes';
import { loginSchema, loginWithSecurityCheckSchema } from '~/data/auth/.schema/loginSchema';
import type { LoginApiResponse, LoginData } from '~/data/auth/.types/loginTypes';

import { Form } from '~/components/.shared/form/Form';

export function LoginForm() {
  const fetcher = useFetcher<DataFunctionResult<LoginData>>();
  const data = useLoaderData<typeof loader>();
  const actionData = useActionData<RouteData<LoginApiResponse>>();
  const location = useLocation();

  let showCaptcha = false;
  if (actionData?.error || fetcher?.data?.error) {
    let errorKeys: string[] = [];
    if (actionData?.error?.messages || fetcher?.data?.error?.messages) {
      errorKeys = Object.keys(actionData?.error?.messages || fetcher.data.error?.messages || {});
    }
    showCaptcha =
      actionData?.error?.status === 429 ||
      fetcher?.data?.error?.status === 429 ||
      errorKeys.includes('h-captcha-response');
  }

  return (
    <Form
      FetcherForm={fetcher.Form}
      fetcherData={fetcher.data}
      method="post"
      action={`${location.pathname}${location.search}`}
      schema={showCaptcha ? loginWithSecurityCheckSchema : loginSchema}
      reloadDocument
    >
      <Form.Fieldset>
        <Form.InputField label="Email address" type="email" name="email" autoComplete="username" />
        <Form.InputField label="Password" type="password" name="password" autoComplete="current-password" />
        <input type="hidden" name="redirectUrl" value={data.data?.redirectUrl || ''} />
        {showCaptcha && <Form.Captcha />}
        <Form.SubmitButton
          className="justify-center w-full"
          value={LoginActions.Login}
          loading={fetcher.state === 'submitting'}
        >
          Log in
        </Form.SubmitButton>
      </Form.Fieldset>
    </Form>
  );
}
