import { Link } from '@remix-run/react';
import { useTypedActionData as useActionData } from 'remix-typedjson';

import type { RouteData } from '~/data/.types/routeTypes';
import type { LoginApiResponse } from '~/data/auth/.types/loginTypes';

import { TwoFactorForm } from './TwoFactorForm';

export function TwoFactor() {
  const actionData = useActionData<RouteData<LoginApiResponse>>();

  const twoFactorFailed = actionData?.error?.values?._action === 'twofactor' && actionData?.error?.type === 'api';

  return (
    <>
      {twoFactorFailed ? (
        <>
          <h1>Incorrect 2FA code</h1>
          <p>The code you entered was incorrect and you will need to start again.</p>
          <Link to="/login">Back to login</Link>
        </>
      ) : (
        <>
          <h1>Enter your 2FA code</h1>
          <p>
            Two-factor authentication has been enabled on your account. Please enter your 6-digit verification code
            below.
          </p>
          <TwoFactorForm />
          <div className="text-center mt-6">
            <Link to="/login?recovery=1">I can't find my code</Link>
          </div>
          <div className="text-center mt-3">
            <a
              href="https://help.gathercontent.com/en/articles/3095685-changing-your-personal-settings#h_7f7707377f"
              target="_blank"
            >
              Read the 2FA help docs
            </a>
          </div>
        </>
      )}
    </>
  );
}
